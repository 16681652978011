.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: #1a1b26;
  color: #c0caf5;
}

.hljs-comment,
.hljs-quote {
  color: #565f89;
  font-style: italic;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
  color: #7aa2f7;
}

.hljs-literal,
.hljs-number,
.hljs-doctag {
  color: #ff9e64;
}

.hljs-string,
.hljs-regexp,
.hljs-title,
.hljs-name,
.hljs-class .hljs-title {
  color: #9ece6a;
}

.hljs-type,
.hljs-built_in,
.hljs-builtin-name,
.hljs-params {
  color: #bb9af7;
}

.hljs-attribute {
  color: #7aa2f7;
}

.hljs-variable,
.hljs-template-variable {
  color: #f7768e;
}

.hljs-code,
.hljs-symbol {
  color: #7aa2f7;
}

.hljs-meta,
.hljs-meta .hljs-keyword,
.hljs-meta .hljs-string {
  color: #ff9e64;
}

.hljs-link {
  color: #7dcfff;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

